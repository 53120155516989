
.video-responsive iframe {
  left: 0;
  top: 0;
  width: 180%;
  height: 210%; 
}

.background { 
  background-color: white ;
  width:100%;
  height:100%;
  min-height:87vh;
  display: flex;
  flex-direction: column;
}

.home-box {
  width: 100%;
  height: 100%;
  /* background-color: yellow; */
  display: flex;
  flex-direction: column;
  min-width: 1500px;
}

.video-box {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: orange; */
  min-height: 50vh;
}

