.timer-box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-color: red; */
  min-height: 25vh;
  font-family: Mario;
}


.timer-display { 
  display: flex; 
  font-size: 16vh;
}

.timer-box h2 {
  font-size: 6vh;
}
.timer-text-box {
  display: flex;
  padding-left: 5vh;
}
.timer-text-display {
  display: flex;
  padding-left: 3vh;
}
.timer-text-display h3{ 
  padding: 0vh 8vh;
  font-size: 5vh; 
}
.timer-text-display-seconds h3 {
  padding-right: 10vh;
  padding-left: 5vh;
  font-size: 5vh;
}
@font-face {
  font-family: Mario;
  src: url(../../assets/fonts/Mario/SuperMario.ttf) format('truetype');
}
